import { ColumnDef } from "@tanstack/react-table";
import Link from "next/link";
import useSWR from "swr";

import { UserAvatar } from "@/components/common/user-avatar";
import { AttachmentIconWithStatus } from "@/components/icons/attachment-icon-with-status";
import { DataTable } from "@/components/table/data-table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { downloadContract } from "@/components/university/approval/api";
import { Approval } from "@/components/university/approval/approval.model";
import { superFetchData } from "@/lib/utils";
import { approvalStatusMapper } from "@/src/constants/status.constant";

const columns = [
  {
    header: "Name",
    cell: ({ row }) => (
      <div className="max-w-[500px] whitespace-normal">
        <Link
          href={`/university/approvals/${row.original.researcher_org_id}?contract_type=${row.original?.contracts?.[0]?.contract_type}`}
          className="p4-medium line-clamp-2 text-emerald-black hover:underline"
        >
          <div
            className="flex items-center gap-2"
            aria-label={`item ${row.original.researcher_org.name}`}
          >
            <UserAvatar
              url={row.original.researcher_org.logo_url}
              name={row.original.researcher_org.name}
            />
            <div>
              <div className="h7">{row.original.researcher_org.name}</div>
            </div>
          </div>
        </Link>
      </div>
    ),
  },
  {
    header: "Contract",
    cell: ({ row }) => (
      <Button variant="ghost" onClick={() => downloadContract(row.original)}>
        <AttachmentIconWithStatus
          url="pdf"
          status={row.original?.status}
          className="mx-auto w-[32px] shrink-0"
        />
      </Button>
    ),
  },
  {
    header: "Type",
    cell: ({ row }) => (
      <span className="p4-medium-mobile capitalize text-black">
        {row.original?.contracts?.[0]?.contract_type} Contract
      </span>
    ),
  },
  {
    header: "Status",
    cell: ({ row }) => {
      let stt =
        approvalStatusMapper[
          row.original?.status as keyof typeof approvalStatusMapper
        ];
      let approvalStatus = stt
        ? {
            text: stt.text,
            variant: stt.variant,
          }
        : {
            text: row.original?.status,
            variant: "warning",
          };

      return (
        <Badge
          className="p4 w-auto min-w-[112px] px-4 py-3"
          variant={approvalStatus.variant as any}
        >
          {approvalStatus.text}
        </Badge>
      );
    },
  },
] as ColumnDef<Approval>[];

export function UniversityApprovalList() {
  const { data } = useSWR("/orgs/approvals", superFetchData, {
    revalidateOnFocus: true,
    revalidateIfStale: true,
  });

  return (
    <div className="px-5 py-10">
      <div className="flex items-center justify-between border-b border-b-coffee-600 pb-2">
        <h2 className="text-emerald-black">Approvals</h2>
      </div>
      <DataTable columns={columns} data={data?.lab_registrations} />
    </div>
  );
}
