"use client";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pagination?: any;
  setPagination?: any;
  pageCount?: any;
  cellClassName?: string;
  headerClassName?: string;
  showHeaders?: boolean;
  hideNoResult?: boolean;
  clickableRow?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data = [],
  pagination,
  setPagination,
  pageCount,
  cellClassName,
  headerClassName,
  showHeaders = true,
  hideNoResult,
  clickableRow = false,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  if (!data?.length) return null;

  return (
    <div className="">
      <Table className="border-separate border-spacing-y-2 rounded-md">
        {showHeaders && (
          <TableHeader className="p3 [&_tr]:border-none">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn("p3-medium text-black", headerClassName)}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
        )}
        <TableBody>
          {table.getRowModel().rows?.length
            ? table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className={cn(
                    "border-b-0 bg-coffee-50",
                    index % 2 === 0 && "bg-interface-white",
                    clickableRow && "cursor-pointer",
                  )}
                  onClick={(event: any) => {
                    if (clickableRow) {
                      const link = event.target
                        .closest("tr")
                        .firstElementChild?.querySelector("a");

                      link?.click();
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "p4 h-[72px] text-ellipsis whitespace-nowrap px-5",
                        cellClassName,
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : !hideNoResult && (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
      {pagination && !!data?.length && (
        <div className="mb-10 mt-[26px] flex items-center justify-end space-x-2">
          <Button
            variant="secondary"
            className="h-[49px] w-[49px]"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            aria-label="Previous page"
          >
            <ChevronLeft size={24} />
          </Button>
          <Button
            variant="secondary"
            className="h-[49px] w-[49px]"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            aria-label="Next page"
          >
            <ChevronRight size={24} />
          </Button>
        </div>
      )}
    </div>
  );
}
