import { Paperclip } from "lucide-react";
import React from "react";

import { ImageResponsive } from "@/components/image";
import { cn } from "@/lib/utils";

interface AttachmentIconProps {
  url: string;
  status: string;
  className?: string;
}

export const AttachmentIconWithStatus: React.FC<AttachmentIconProps> = ({
  url,
  status,
  className,
}) => {
  let iconSrc = "";

  const type = url.split(".").pop();

  switch (type) {
    case "pdf":
      iconSrc = "/icons/pdf.svg";
      break;
    case "jpg":
    case "jpeg":
    case "png":
      iconSrc = "/icons/image.svg";
      break;
    case "docx":
      iconSrc = "/icons/doc.svg";
      break;
    case "doc":
      iconSrc = "/icons/doc.svg";
      break;
    case "txt":
      iconSrc = "/icons/txt.svg";
      break;
  }

  let checkIconSrc = "/icons/approvals/check-green.svg";
  switch (status) {
    case "approved":
      checkIconSrc = "/icons/approvals/check-green.svg";
      break;
    case "pending":
      checkIconSrc = "/icons/approvals/clock-orange.svg";
      break;
    case "uni_declined":
      checkIconSrc = "/icons/approvals/warning-red.svg";
      break;
    case "lab_declined":
      checkIconSrc = "/icons/approvals/warning-red.svg";
      break;
    case "pending_uni_approve":
      checkIconSrc = "/icons/approvals/check-orange.svg";
      break;
    case "pending_lab_approve":
      checkIconSrc = "/icons/approvals/clock-orange.svg";
      break;
    case "pending_lab_more_info":
      checkIconSrc = "/icons/approvals/warning-orange.svg";
      break;
    case "pending_uni_more_info":
      checkIconSrc = "/icons/approvals/warning-orange.svg";
      break;
  }

  return (
    <div className={cn("relative shrink-0", className)}>
      {iconSrc ? (
        <ImageResponsive
          src={iconSrc}
          alt={`Attachment icon for ${type}`}
          className="display-block"
        />
      ) : (
        <Paperclip size={24} strokeWidth={1} className="display-block0" />
      )}
      <ImageResponsive
        src={checkIconSrc}
        alt={`Check icon for ${type}`}
        className="size-xs absolute -bottom-2 -right-2"
        width={18}
        height={18}
      />
    </div>
  );
};
